.main {
  margin-top: 2rem;
  /* width: 50vw; */
}
.main h2 {
  text-align: center;
}
.schedules {
  width: 100vw;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.schedule {
  margin: 1rem 0;
  display: flex;
  width: 70%;
  border: 3px solid skyblue;
  border-radius: 70px;
}
@media (max-width:780px){
  .schedule{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
.sche1 {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sche2 {
  width: 50%;
}
