/* Chart container styles */
.chart-container {
    width: 90%;
    max-width: 600px;
    margin: 0 auto;
    text-align: center;
  }
  
  /* Chart title and descriptions */
  .chart-title {
    font-size: 2rem;
    margin-bottom: 10px;
  }
  .boxtoken{
    margin-top: 5rem;
    margin-bottom: -5rem;
  }
  
  .chart-supply {
    font-size: 1rem;
    margin-bottom: 20px;
  }
  
  .chart-description {
    font-size: 0.9rem;
    margin-bottom: 30px;
  }
  
  /* Media Queries for Responsive Design */
  @media (max-width: 768px) {
    .chart-title {
      font-size: 1.3rem;
    }
  
    .chart-supply {
      font-size: 0.9rem;
    }
  
    .chart-description {
      font-size: 0.8rem;
    }
  }
  
  @media (max-width: 480px) {
    .chart-title {
      font-size: 1.2rem;
    }
  
    .chart-supply {
      font-size: 0.8rem;
    }
  
    .chart-description {
      font-size: 0.7rem;
    }
  }
  