.header {
  height: 30vh;
  /* border: 2px solid red; */
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.box1 {
  border: 5px solid skyblue;
  height: 30%;
  width: 14%;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 2rem;
  box-shadow: 0 0 15px 5px rgba(255, 255, 255, 0.8);
}

.box1 i {
  font-size: 35px;

  color: white;
  border-radius: 50%;
  margin: 0 5px;
}
i:hover {
  color: skyblue;
}
.box1 a {
  font-size: 20px;
  text-decoration: none;
  color: white;
}
.box2 {
  border: 10px solid skyblue;
  border-top: none;
  border-bottom-left-radius: 70px;
  border-bottom-right-radius: 70px;
  height: 100%;
  width: 40%;
  box-shadow: 0 0 15px 5px rgba(255, 255, 255, 0.8);
}
.box21 {
  height: 75%;
  width: 100%;
  /* border: 2px solid red; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.box212 {
  /* color: skyblue; */
  margin-left: 1rem;
}
.box212 h1 {
  font-weight: bolder;
}
.coin-logo img {
  border-radius: 50%;
  height: 120px;
  width: 150px;
}

.box22 {
  display: flex;
  justify-content: center;
  height: 25%;
}
.join {
  background-color: rgb(60, 62, 63);
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  height: 85%;
  width: 90%;
}
.copy {
  font-size: 20px;
}
.address p {
  margin-bottom: -5px;
}

@media (max-width: 780px) {
  .header {
    width: 100%;
    height: auto;
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  .box1{
    width: auto;
    padding: 10px;
    margin: 20px 0;
  }
  .box2{
    width: auto;
  }
  .box21{
    display: flex;
    flex-direction: column;
    width: 80vw;
  }
 
}
